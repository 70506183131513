const substituteStores = [
  // ICA Supermarket Åre
  { physicalAccountId: '1004338', commerceAccountId: '1080018' },
]

/*
  Removes physical stores which have a commerce equivalent store and
  substitute it's commerce store name with the physical store name
  ---
  For example, this will remove "ICA Supermarket Åre" because it's
  a physical-only store, but retain it's equivalent commerce store
  "ICA Supermarket Åre (e-handelslager)" and change it's name to
  "ICA Supermarket Åre"
*/
export const substitutePhysicalForCommerceStores = (stores) => {
  const accountIdsToExclude = substituteStores.map(
    (store) => store.physicalAccountId
  )

  return stores
    .filter((store) => !accountIdsToExclude.includes(store.accountId))
    .map((store) => {
      const substituteStore = substituteStores.find(
        ({ commerceAccountId }) => commerceAccountId === store.accountId
      )

      if (substituteStore) {
        const physicalStore = stores.find(
          (_store) => _store.accountId === substituteStore.physicalAccountId
        )

        if (physicalStore) {
          return { ...store, name: physicalStore.name }
        }
      }

      return store
    })
}
